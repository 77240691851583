.star-rating {
    display: flex;
    gap: 5px;
  }
  
  .star {
    font-size: 2rem;
    cursor: pointer;
  }
  
  .star.filled {
    color: gold;
  }
  