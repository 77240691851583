/* AddBottle.css */
.add-bottle-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-bottle-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .add-bottle-container .form-group {
    margin-bottom: 15px;
  }
  
  .add-bottle-container .form-control {
    border-radius: 5px;
    padding: 10px;
  }
  
  .add-bottle-container .btn {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
  }
  